import React from "react";
import ParticlesBackground from './components/Particles/ParticlesBackground'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { Container } from "@material-ui/core";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Main from "./components/Main/Main";
import MusicVideo from "./components/MusicVideo/MusicVideo";
import About from "./components/About/About";
import News from "./components/News/News";
import Dates from "./components/Dates/Dates";
import Contact from "./components/Contact/Contact";


const App = () => {
    return (
      <Container maxWidth="md" className="App">
        <Router>
          <Navbar />
          <div>
            <Switch>
              <Route exact path="/" component={Main} />
              <Route path="/musicvideo" component={MusicVideo} />
              <Route path="/about" component={About} />
              <Route path="/news" component={News} />
              <Route path="/dates" component={Dates} />
              <Route path="/contact" component={Contact} />
            </Switch>
          </div>
          <Footer />
          <ParticlesBackground/>
        </Router>
      </Container>
    );
  };

export default App;
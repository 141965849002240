import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles((theme) => ({}));

export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <p style={{ color: "#fb4570", fontWeight: 800, letterSpacing: 8 }}>XXX</p>
      </Button>
      <Menu
        style={{ borderRadius: "-10px" }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Link to="/">
          <MenuItem
            style={{ background: "black" }}
            className={classes.appBar}
            onClick={handleClose}
          >
            HOME
          </MenuItem>
        </Link>
        <Link to="/musicvideo">
          <MenuItem style={{ background: "black" }} onClick={handleClose}>
            MUSIC WORK
          </MenuItem>{" "}
        </Link>
        <Link to="/about">
          <MenuItem style={{ background: "black" }} onClick={handleClose}>
            ABOUT
          </MenuItem>
        </Link>
        <Link to="/news">
          <MenuItem style={{ background: "black" }} onClick={handleClose}>
            PRESS
          </MenuItem>
        </Link>
        <Link to="/contact">
          <MenuItem style={{ background: "black" }} onClick={handleClose}>
            CONTACT
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
}

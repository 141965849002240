import React, { useState } from "react";
import { Carousel } from "react-carousel-minimal";

import "./About.css";

const About = () => {
  const [readMore, setReadMore] = useState(false);

  const data = [
    {
      image: "./H39A3234.jpg",
    },
    {
      image: "./H39A3226.jpg",
    },
    {
      image: "./H39A3579.jpg",
    },
  ];

  const extraContent = (
    <div>
      <p className="extra-content">
        Initiated by the pandemic she started reflecting on her career and
        followed the opportunity and her drive to move on to more daring
        projects. "It's a moment of renewal, of producing with more time and
        being able to focus on the creative side, which is where I've always
        wanted to go," Grace says, referring to the production of her new music
        and video clips.
      </p>
      <p className="extra-content">
        With the release of MOUSSY and PPK, Grace is reaching a pinnacle in her
        work. After many years of observing and translating feelings, she
        continues to inspire the world with her music. By elevating it to a
        political instrument,she makes the representation, legitimisation and
        empowerment of bodies and desire experiential and visible.
      </p>
      <p className="extra-content">
        "In that pandemic moment, I had to work a lot on my self-esteem. Then I
        thought about how many other sisters might feel the same way," Grace
        says about the importance of collective care as a powerful tool in the
        construction of identities, included in music.
      </p>
      <p className="extra-content">
        Grace is not only a DJ, producer and director, she also is part of the
        women's band Rainhas do Norte. where she plays and sings Brazilian
        northeast rhythms (such as maracatu, coco, ciranda, baião, frevo and
        ijexá) since 2003, delighting a growing audience. The band's music has
        already been released on the Globalist label's CD titled 'Cada Uma'.
      </p>
      <p className="extra-content">
        Grace is also a member of a Berlin-based Brazilian activist group who
        are positioning themselves against the Brazilian parliamentary coup and
        the disastrous government that followed. She draws strength from her
        personal experiences and struggle as she is constantly confronted with
        structural and systemic racism that forces her to overcome repeated
        obstacles and fight for the future she wants to live in and build.
      </p>
      <p className="extra-content">
        Grace is actively involved in political collectives, co-founded the
        queer party collective ¡MASH UP! - Multigender/Multiworld and organised
        the first F.L.I.N.T Pride in Berlin with the queer-feminist collective
        OYA, among others.
      </p>
    </div>
  );

  const linkName = readMore ? "READ LESS" : "READ MORE";

  return (
    <>
      <div className="container2" >
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              padding: "0 0px",
            }}
          >
            <Carousel
              data={data}
              time={5000}
              width="100%"
              height="500px"
              slideNumber={false}
              automatic={true}
              dots={false}
              pauseIconColor="white"
              slideBackgroundColor="black"
              slideImageFit="cover"
              style={{
                textAlign: "center",
                maxWidth: "850px",
                maxHeight: "500px",
                margin: "90px auto",
              }}
            />
          </div>
        </div>

        <p className="body1">
          Born in Bahia and now touring internationally, Grace Kelly invigorates
          and enriches Berlin's intercultural DJ scene since her arrival in
          1996. Growing up with Brazilian musical traditions and shaped by rural
          and urban influences, she debuts in 1998 as a percussionist in Afoxé
          Loni, a carnival block that opened the Carnival of Cultures in Berlin
          for years.
        </p>
        <p className="body1">
          After her success as a DJ at the Heimatklänge Festival, her popularity
          and demands for her stage presence rose and her work became
          increasingly noticed. Invitations from popular theatres and clubs,
          including Grüner Salon, Tränenpalast, Hebbel am Ufer, Volksbühne, BKA
          Theater, Haus der Kulturen der Welt, Liquidrom, Far Out,
          Kulturbrauerei, Trumpet, Lido and festivals such as the Berlinale and
          Berliner Festspiele followed.
        </p>
        <p className="body1">
          As a travelling advocate of political transformation, she has made
          bodies vibrate and spaces shake in Mykonos, Bali, the UK, Italy,
          Morocco, Poland, Luxembourg, Brazil, Switzerland, Austria, Israel and
          the Netherlands. She fuses Afro-Brazilian and Latin music with electro
          and house, oriental breakbeats and Eastern European rhythms to convey
          her unique style. On top of that, for years Grace produces the monthly
          event Mundo Mix which attracts an international audience well outside
          the borders of Berlin. In her radio show on MultiKulti RBB, she
          spreads Brazilian music beyond the clichés and the mainstream.
        </p>

        <a
          className="read-more-link"
          onClick={() => {
            setReadMore(!readMore);
          }}
          href
        >
          <h2>{linkName}</h2>
        </a>
        {readMore && extraContent}
      </div>
    </>
  );
};

export default About;

import React from "react";
import Particles from "react-particles-js";
import { useMediaPredicate } from "react-media-hook";

import "./ParticlesBackground.css";
var style = {
  width: "100vw",
};

const ParticlesBackground = () => {

    const biggerThan700 = useMediaPredicate("(min-width: 770px)");

  return (
    <div style={style}>
      <Particles
        id="particle-canvas"
        params={{
          particles: {
            number: {
              value: biggerThan700 ? 60 : 40,
            },
            size: {
              value: 2,
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
            },
          },
        }}
      />
    </div>
  );
};

export default ParticlesBackground;

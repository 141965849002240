import React, { useEffect } from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="contact">
      <p>
      Publicity, press or shows: <strong>oxente@web.de</strong>
      </p>
    </div>
  );
};

export default Contact;

import React, { useEffect } from "react";
import { AppBar, Toolbar, Typography, Container } from "@material-ui/core";
import "./Footer.css";

const Footer = () => {
  const getYear = () => {
    return new Date().getFullYear();
  };
  return (
    <div className="container">
      <Container maxWidth="sm">
        <div className="social-network-links">
          <ul>
            <li>
              <a
                onMouseOver="this.style.color='black'"
                style={{ color: "white", paddingBottom: "10px" }}
                href="https://www.facebook.com/djgracekelly"
                className="fa fa-facebook"
                target="_blank"
                rel="noreferrer"
              ></a>
            </li>
            <li>
              <a
                style={{ color: "white" }}
                href="https://www.instagram.com/djgracekelly/"
                className="fa fa-instagram"
                target="_blank"
                rel="noreferrer"
              ></a>
            </li>
            <li>
              <a
                style={{ color: "white" }}
                href="https://www.youtube.com/channel/UCa-4yG0PvFdwd0bybl1VAUA"
                className="fa fa-youtube"
                target="_blank"
                rel="noreferrer"
              ></a>
            </li>
            <li>
              <a
                style={{ color: "white" }}
                href="https://open.spotify.com/artist/2MfKLjemkpnCN5RggJfMQA"
                className="fa fa-spotify"
                target="_blank"
                rel="noreferrer"
              ></a>
            </li>
            <li>
              <a
                style={{ color: "white" }}
                href="https://music.apple.com/de/artist/dj-grace-kelly/261078458"
                className="fa fa-apple"
                target="_blank"
                rel="noreferrer"
              ></a>
            </li>
            <li>
              <a
                style={{ color: "white" }}
                href="https://soundcloud.com/djgracekelly"
                className="fa fa-soundcloud"
                target="_blank"
                rel="noreferrer"
              ></a>
            </li>
            <li>
              <a
                style={{ color: "white" }}
                href="https://www.deezer.com/br/artist/9905730"
                className="fa fa-deezer"
                target="_blank"
                rel="noreferrer"
              >
                deezer
              </a>
            </li>
          </ul>
        </div>
        <div>
          <Toolbar style={{ fontSize: "10px", color: "white" }}>
            <ul className="toolbar">
              <a
                href="https://blacknim-agency-eo3so7ws8.vercel.app/"
                target="_blank"
                rel="noreferrer"
                alt="Pornceptualtropical-2021-07-18"
              >
                <li>© {getYear()} NIM</li>
              </a>
              <li>PRIVACY POLICY</li>
              <li>TERMS OF USE</li>
            </ul>
          </Toolbar>
        </div>
      </Container>
    </div>
  );
};

export default Footer;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import "./Main.css";
import video1 from "./Moussy_shortcut-3.mp4";

const Main = () => {
  useEffect(() => {
    document.getElementById("vid").play();
  }, []);

  const changeColor = () => {
    var newColor = this.state.color === "white" ? "black" : "white";
    this.setState({ color: newColor });
  };

  return (
    <>
      <div onClick={changeColor} className="vid-container">
        <video preload loop id="vid" className="bg-video" autoplay muted>
          <source type="video/mp4" src={video1}></source>
        </video>
      </div>
      <div className="moreCTA">
        <Link className="read-more-link" onClick={() => {}} to="/about">
          <h2>READ MORE</h2>
        </Link>
      </div>
    </>
  );
};

export default Main;

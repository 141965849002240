import React, { useEffect } from "react";
import "./Dates.css";

const Dates = () => {
  return (
    <div>
      <img alt="nothing to see here" src="./pexels-aleksandar-pasaric-3342739.jpg"/>
    </div>
  );
};

export default Dates;

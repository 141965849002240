import React from "react";
import Gallery from "react-grid-gallery";

const News = () => {
  const images = [
    "Kopie von Cultura_Preta1.jpg",
    "img-3.jpg",
    "img-4.jpg",
    "img-2.jpg",
    "Kopie von Cultura_Preta-Foto (1).jpg",
    "img-5.jpg",
  ];

  const IMAGES = [
    {
      src: "Kopie von Cultura_Preta1.jpg",
      thumbnail: "Kopie von Cultura_Preta1.jpg",
    },
    {
      src: "img-3.jpg",
      thumbnail: "img-3.jpg",
    },

    {
      src: "img-4.jpg",
      thumbnail: "img-4.jpg",
    },
    {
      src: "img-2.jpg",
      thumbnail: "img-2.jpg",
    },
    {
      src: "Kopie von Cultura_Preta-Foto (1).jpg",
      thumbnail: "Kopie von Cultura_Preta-Foto (1).jpg",
    },
    {
      src: "img-5.jpg",
      thumbnail: "img-5.jpg",
    },
  ];

  return (
    <div style={{  display: "flex", flexDirection: "column", marginBottom: "50%", marginTop:"15%", marginLeft: "20px" }}>
      <Gallery images={IMAGES} />,
    </div>
  );
};

export default News;

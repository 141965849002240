import { Typography } from "@material-ui/core";

// export default function MusicVideo() {
//   const classes = useStyles();

//   function FormRowVideo() {
//     return (
//       <React.Fragment>
//         <Grid item xs={3}>
//           <a
//             href="https://www.youtube.com/watch?v=XlCV0D8H6Aw"
//             target="_blank"
//             rel="noreferrer"
//             alt="Track name is zuhause"
//           >
//             <img
//               className={classes.image}
//               rel="noreferrer"
//               src="https://i1.sndcdn.com/artworks-T7CGm7pknO0J-0-t500x500.jpg"
//             />
//           </a>
//         </Grid>
//       </React.Fragment>
//     );
//   }
//   function FormRowMusic() {
//     return (
//       <React.Fragment>
//         <Grid item xs={3}>
//           <a
//             href="https://soundcloud.com/djgracekelly/zuhause"
//             target="_blank"
//             rel="noreferrer"
//             alt="Track name is zuhause"
//           >
//             <img
//               className={classes.image}
//               src="https://i1.sndcdn.com/artworks-21j1IfncesndUJtr-0hrGIw-t200x200.jpg"
//             />
//           </a>
//         </Grid>
//         <Grid item xs={3}>
//           <a
//             href="https://soundcloud.com/djgracekelly/oneyearquarentena"
//             target="_blank"
//             rel="noreferrer"
//             alt="Track name is Genocida"
//           >
//             <img
//               className={classes.image}
//               rel="noreferrer"
//               src="https://i1.sndcdn.com/artworks-Xhef5jvKGVGa01Br-AppLoQ-t200x200.jpg"
//             />
//           </a>
//         </Grid>
//         <Grid item xs={3}>
//           <a
//             href="https://soundcloud.com/djgracekelly/livesetirupe-burningman-zaragata"
//             target="_blank"
//             rel="noreferrer"
//             alt="Track name is Burningman Zaragata"
//           >
//             <img
//               className={classes.image}
//               rel="noreferrer"
//               src="https://i1.sndcdn.com/artworks-7W2Z2xJ4PPSiAp5p-MfUD9A-t200x200.jpg"
//             />
//           </a>
//         </Grid>
//         <Grid item xs={3}>
//           <a
//             href="https://soundcloud.com/djgracekelly/mashup-solilive-1"
//             target="_blank"
//             rel="noreferrer"
//             alt="Track name is zuhause"
//           >
//             <img
//               className={classes.image}
//               src="https://i1.sndcdn.com/artworks-4VrrRvV1bzM9x2yW-y1b6vw-t200x200.jpg"
//             />
//           </a>
//         </Grid> <Grid item xs={3}>
//           <a
//             href="https://soundcloud.com/djgracekelly/kuarentenasolar"
//             target="_blank"
//             rel="noreferrer"
//             alt="Track name is kuarentenasolar"
//           >
//             <img
//               className={classes.image}
//               src="https://i1.sndcdn.com/artworks-It3WdcNCKdusTqyM-CS2C4g-t200x200.jpg"
//             />
//           </a>
//         </Grid> <Grid item xs={3}>
//           <a
//             href="https://soundcloud.com/inlapse/inlapse-varias-queixas-remix"
//             target="_blank"
//             rel="noreferrer"
//             alt="Track name is inlapse-varias-queixas-remix"
//           >
//             <img
//               className={classes.image}
//               src="https://i1.sndcdn.com/artworks-fffpuBnnyMXiw1kJ-e8yPKg-t200x200.jpg"
//             />
//           </a>
//         </Grid> <Grid item xs={3}>
//           <a
//             href="https://soundcloud.com/djgracekelly/thfradioshow"
//             target="_blank"
//             rel="noreferrer"
//             alt="Track name is thf radio show"
//           >
//             <img
//               className={classes.image}
//               src="https://i1.sndcdn.com/artworks-upUf8S9UYqCNpJHW-u9KGdA-t200x200.jpg"
//             />
//           </a>
//         </Grid> <Grid item xs={3}>
//           <a
//             href="https://soundcloud.com/djgracekelly/outunbreezeloungeset"
//             target="_blank"
//             rel="noreferrer"
//             alt="Track name is zuhause"
//           >
//             <img
//               className={classes.image}
//               src="https://i1.sndcdn.com/artworks-Vt7qCIcFKvYQjwOP-qdj8Yg-t200x200.jpg"
//             />
//           </a>
//         </Grid> <Grid item xs={3}>
//           <a
//             href="https://soundcloud.com/djgracekelly/menschmeier5jahrequarentena"
//             target="_blank"
//             rel="noreferrer"
//             alt="Track name is zuhause"
//           >
//             <img
//               className={classes.image}
//               src="https://i1.sndcdn.com/artworks-91AEFHgV3l9uz2vx-UMi7Ww-t200x200.jpg"
//             />
//           </a>
//         </Grid> <Grid item xs={3}>
//           <a
//             href="https://soundcloud.com/djgracekelly/redlightduplexcarnavalstoandre"
//             target="_blank"
//             rel="noreferrer"
//             alt="Track name is zuhause"
//           >
//             <img
//               className={classes.image}
//               src="https://i1.sndcdn.com/artworks-WoNGsACdgxGUMlnD-7qo5Hw-t200x200.jpg"
//             />
//           </a>
//         </Grid>
//       </React.Fragment>
//     );
//   }

//   return (
//     <div className={classes.root}>
//       <div className={classes.video}>
//         <Typography className={classes.label}>SINGLE VIDEO</Typography>
//         <Grid container spacing={6}>
//           <Grid container item xs={1} spacing={3}>
//             <FormRowVideo />
//           </Grid>
//         </Grid>
//       </div>
//       <div className={classes.music}>
//         <Typography className={classes.label}>MUSIC</Typography>
//         <Grid container spacing={1}>
//           <Grid container item xs={12} spacing={3}>
//             <FormRowMusic />
//           </Grid>
//         </Grid>
//       </div>
//     </div>
//   );
// }

import React from "react";
import "./MusicVideo.css";
import Card from "./Card";
import { Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  gridContainer: {
    marginTop: "90px",
    marginBottom: "90px",
  },
});

export default function MusicVideo() {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={3}
      className={classes.gridContainer}
      justify="start"
      style={{ marginBottom: "50%" }}
    >
      <Grid item xs={12} md={12} lg={12}>
        <Typography className={classes.label}>SINGLE VIDEO</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <a
          href="https://www.youtube.com/watch?v=98PGSQ1TpRo"
          target="_blank"
          rel="noreferrer"
          alt="Moussy Videoclip"
        >
          <Card
            color="#DB0351"
            url="https://i1.sndcdn.com/avatars-KUJDdbE4pXVRjzJ4-NujlSw-t500x500.jpg"
            title="Track name is zuhause"
            link=""
          />
        </a>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <a
          href="https://www.youtube.com/watch?v=XlCV0D8H6Aw"
          target="_blank"
          rel="noreferrer"
          alt="Track name is zuhause"
        >
          <Card
            color="#6ffa03"
            url="https://i1.sndcdn.com/artworks-T7CGm7pknO0J-0-t500x500.jpg"
            title="Track name is zuhause"
            link=""
          />
        </a>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Typography className={classes.label}>MUSIC</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <a
          href="https://soundcloud.com/djgracekelly/pornceptualtropical-2021-07-18"
          target="_blank"
          rel="noreferrer"
          alt="Pornceptualtropical-2021-07-18"
        >
          <Card
            color="#F7F8FA"
            url="https://i1.sndcdn.com/artworks-lBlTrBU2ZZi7jsM2-hu2quQ-t500x500.jpg"
            title="Pornceptualtropical-2021-07-18"
            link=""
          />
        </a>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <a
          href="https://soundcloud.com/djgracekelly/zuhause"
          target="_blank"
          rel="noreferrer"
          alt="Pornceptualtropical-2021-07-18"
        >
          <Card
            color="#E0C595"
            url="https://i1.sndcdn.com/artworks-21j1IfncesndUJtr-0hrGIw-t200x200.jpg"
            title="zuhause"
            link=""
          />
        </a>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <a
          href="https://soundcloud.com/djgracekelly/livesetirupe-burningman-zaragata"
          target="_blank"
          rel="noreferrer"
          alt="Burningman-Zaragata/Irupê"
        >
          <Card
            color="#804C5D"
            url="https://i1.sndcdn.com/artworks-7W2Z2xJ4PPSiAp5p-MfUD9A-t200x200.jpg"
            title="Burningman-Zaragata/Irupê"
            link=""
          />
        </a>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <a
          href="https://soundcloud.com/djgracekelly/oneyearquarentena"
          target="_blank"
          rel="noreferrer"
          alt="Pornceptualtropical-2021-07-18"
        >
          <Card
            color="white"
            url="https://i1.sndcdn.com/artworks-Xhef5jvKGVGa01Br-AppLoQ-t200x200.jpg"
            title="Track name is Genocida"
            link=""
          />
        </a>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
      <a
          href="https://soundcloud.com/djgracekelly/mashup-solilive-1"
          target="_blank"
          rel="noreferrer"
          alt="MashUp-SoliLive"
        >
        <Card
          color="#EB0189"
          url="https://i1.sndcdn.com/artworks-4VrrRvV1bzM9x2yW-y1b6vw-t200x200.jpg"
          title="MashUp-SoliLive"
          link=""
        />
        </a>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
      <a
          href="https://soundcloud.com/djgracekelly/kuarentenasolar"
          target="_blank"
          rel="noreferrer"
          alt="Pornceptualtropical-2021-07-18"
        >
        <Card
          color="#788349"
          url="https://i1.sndcdn.com/artworks-It3WdcNCKdusTqyM-CS2C4g-t200x200.jpg"
          title="Track name is Genocida"
          link=""
        />
        </a>
      </Grid>
      {/* <Grid item xs={12} sm={6} md={4}>
        <Card
          color="#1E1E1C"
          url="https://i1.sndcdn.com/artworks-fffpuBnnyMXiw1kJ-e8yPKg-t200x200.jpg"
          title="Track name is Genocida"
          link=""
        />
      </Grid> */}
      <Grid item xs={12} sm={6} md={4}>
      <a
          href="https://soundcloud.com/djgracekelly/thfradioshow"
          target="_blank"
          rel="noreferrer"
          alt="Pornceptualtropical-2021-07-18"
        >
        <Card
          color="#9FBDAF"
          url="https://i1.sndcdn.com/artworks-upUf8S9UYqCNpJHW-u9KGdA-t200x200.jpg"
          title="Track name is Genocida"
          link=""
        />
        </a>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
      <a
          href="https://soundcloud.com/djgracekelly/outunbreezeloungeset"
          target="_blank"
          rel="noreferrer"
          alt="Pornceptualtropical-2021-07-18"
        >
        <Card
          color="#E5DCC2"
          url="https://i1.sndcdn.com/artworks-Vt7qCIcFKvYQjwOP-qdj8Yg-t200x200.jpg"
          title="Track name is Genocida"
          link=""
        />
        </a>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
      <a
          href="https://soundcloud.com/djgracekelly/redlightduplexcarnavalstoandre"
          target="_blank"
          rel="noreferrer"
          alt="Pornceptualtropical-2021-07-18"
        >
        <Card
          color="#623928"
          url="https://i1.sndcdn.com/artworks-WoNGsACdgxGUMlnD-7qo5Hw-t200x200.jpg"
          title="Track name is Genocida"
          link=""
        />
        </a>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
      <a
          href="https://soundcloud.com/djgracekelly/menschmeier5jahrequarentena"
          target="_blank"
          rel="noreferrer"
          alt="Pornceptualtropical-2021-07-18"
        >
        <Card
          color="#C79C68"
          url="https://i1.sndcdn.com/artworks-91AEFHgV3l9uz2vx-UMi7Ww-t200x200.jpg"
          title="Track name is Genocida"
          link=""
        />
        </a>
      </Grid>
    </Grid>
  );
}

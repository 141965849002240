import React from "react";
import { Link } from "react-router-dom";
import Hamburguer from "../Hamburguer";

import "./Navbar.css";

const Navbar = () => {
  return (
    <>
      <p className="title">
        <Link to="/">DJ GRACE KELLY</Link>
      </p>
      <div className="show">
        <Hamburguer />
      </div>
      <div className="hide">
        <nav>
          <ul className="horiz-menu">
            <li>
              <Link to="/musicvideo">MUSIC WORK</Link>
            </li>
            <li>
              <Link to="/about">ABOUT</Link>
            </li>
            <li>
              <Link to="/news">PRESS</Link>
            </li>
            <li>
              <Link to="/contact">CONTACT</Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
